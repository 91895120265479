import i18n from "i18next";
import { isLtr } from "./common";

export const switchLanguage = (lang, navigate, location) => {
  i18n.changeLanguage(lang);
  const state = location.state;

  // Update the URL path
  const currentPath = window.location.pathname + window.location.search;
  if (lang === "en") {
    const newPath = `/en${currentPath}`;
    navigate(newPath, { state });
  } else {
    const newPath = currentPath.startsWith("/en")
      ? currentPath.substring("/en".length)
      : currentPath;
    navigate(newPath, { state });
  }
  handleLayout();
};

export const handleLayout = () => {
  if (isLtr()) {
    document.documentElement.setAttribute("lang", "en");
    document.documentElement.setAttribute("dir", "ltr");
    document.body.classList.remove("rtl");
  } else {
    document.documentElement.setAttribute("lang", "ar");
    document.documentElement.setAttribute("dir", "rtl");
    document.body.classList.add("rtl");
  }
};
