import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  Button,
  NotificationHelper,
  Loader,
  Breadcrumb,
  PaymentProcessor,
  Icon,
  TamaraWidget
} from "./";
import {
  calculateTotal,
  checkTamaraOrder,
  classes,
  editAddonsCart,
  getOffersAndVariantsPayload,
  handleAddDeleteItem
} from "../../util/addonsUtils";
import {
  currentLanguage,
  deleteStorage,
  dispatchStorage,
  getFormatedDate,
  getQueryData,
  scrollToSection
} from "../../util/common";
import { useDispatch, useSelector } from "react-redux";
import AddonsService from "../../services/addonsService";
import { Formik, Form, Field } from "formik";
import { eShopToMyAccount, paymentCards } from "../../util/constants";
import { removeItem } from "../../redux/reducers/cartReducer";
import moment from "moment";
import { GET_MEDIA_URL } from "../../config/webService";
import { editEshopCart } from "../../util/eshopUtils";
import { useAppNavigation } from "../../hooks";

const OrdersCheckout = (props) => {
  const {
    module = "eshop",
    setTab,
    hasAddons,
    setAddonsView,
    values,
    draftData,
    setCart,
    settings,
    setAutoRouteCheckout = () => {}
  } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const { toShop, toUrlWithData, localeRoutes } = useAppNavigation();
  const dispatch = useDispatch();
  const { items: cartItems, total: cartTotal } = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user.details);
  // const vatFormRef = useRef(null);

  const lang = currentLanguage();
  const hyperpayId = getQueryData("id");
  const cadPrice = settings?.cadPrice || draftData?.cadPrice;

  const [loader, setLoader] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(module !== "subscription");
  const [myselfPurchase, setMyselfPurchase] = useState(false);
  const [paymentFormDisplay, setPaymentFormDisplay] = useState(hyperpayId);
  const [cardType, setCardType] = useState("VISA");
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  // const [vatError, setVatError] = useState(false);

  useEffect(() => {
    if (module === "upsell") scrollToSection("track-checkout", -100);
    else if (module === "eshop" && !cartItems.length) toShop();

    checkTamaraOrder();
  }, []);

  const showAddons = hasAddons && values.activeStates.activateForAddOnFlow;

  const handleOrderAndPayment = () => {
    if (!termsAccepted || !myselfPurchase) return;
    else if (totalPrice <= 0) return NotificationHelper.error(t("addons.zeroAmountOrder"));
    else if (module === "eshop" && (!user.firstName || !user.lastName))
      return NotificationHelper.info(
        <>
          {t("addons.usernameForOrder")}{" "}
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => toUrlWithData(localeRoutes.myAccount[lang], { from: eShopToMyAccount })}>
            {t("addons.accountLinkForOrder")}
          </span>
        </>,
        { closeOnClick: true }
      );
    else if (cardType === "TAMARA" && (totalPrice < 1 || totalPrice > 7000))
      return NotificationHelper.error(t("addons.tamaraAmountCutoff"));

    // const userVat = vatFormRef.current || { values: { vat: {} }, errors: {} };
    // if (!paymentFormDisplay && userVat.values.vat && Object.keys(userVat.errors).length)
    //   return setVatError(true);
    // setVatError("");

    let valuesToDraft = {
      cardType,
      coupon,
      hyperpayConfig: { module, totalPrice, card: cardType }
    };
    if (module === "eshop") {
      valuesToDraft = {
        ...valuesToDraft,
        variants: cartItems
      };
    } else {
      valuesToDraft = {
        ...values,
        ...valuesToDraft,
        cadPrice
      };
    }

    if (module === "appointment") valuesToDraft.hyperpayConfig.appointmentUrlState = location.state;
    // if (userVat.values.vat) valuesToDraft["userVat"] = userVat.values;
    dispatchStorage("draftVals", valuesToDraft, "sessionStorage");
    handlPaymentFormDisplay();
  };

  const handlPaymentFormDisplay = () => {
    if (paymentFormDisplay) deleteStorage("draftVals", "sessionStorage");
    setPaymentFormDisplay(!paymentFormDisplay);
  };

  const goBack = () => {
    showAddons || module === "upsell"
      ? (setAddonsView(1), setAutoRouteCheckout(false))
      : module === "eshop"
        ? toShop()
        : module === "subscription"
          ? setTab(1)
          : setTab(2);
  };

  const handleCardType = (e) => setCardType(e.target.value);

  const handleTermsChange = (e) => setTermsAccepted(e.target.checked);
  const handlePurchaseTypeChange = (e) => setMyselfPurchase(e.target.checked);

  let items = [];
  let userCart = { offers: [], variants: [] };
  let appointmentPrice;
  let subTotalPrice = 0;
  if (module === "eshop") {
    items = [...cartItems];
    userCart.variants = getOffersAndVariantsPayload(cartItems);
    subTotalPrice = cartTotal;
  } else {
    items = [...values.cart.offers, ...values.cart.products];

    const offers = [...values.cart.offers];
    if (values.cart?.paidOffer) offers.push({ ...values.cart.paidOffer });

    userCart.offers = getOffersAndVariantsPayload(offers);
    userCart.variants = getOffersAndVariantsPayload(values.cart.products);
    appointmentPrice = values.cart.paidOffer?.price || null;
    subTotalPrice = calculateTotal(items, "price") + appointmentPrice;
  }
  const promoDiscount = discount;
  let totalPrice = (subTotalPrice - promoDiscount).toFixed(2);
  if (values?.appointment?.cad) {
    totalPrice = (Number(totalPrice) + cadPrice).toFixed(2);
  }

  let selectedService;
  let appointmentDate;
  let appointmentTime;
  let appointmentBranch;
  let isEstimation;
  let isQuotation;

  if (module === "appointment") {
    selectedService = values.service.name["value_" + lang];
    appointmentDate = values.appointment?.date;
    appointmentTime = values.appointment.services[0]?.slot;
    appointmentBranch = values.appointment.branchName?.["value_" + lang];

    isEstimation = values.appointment.estimationId;
    isQuotation = values.appointment.userQuotation;
  }

  const handleCouponApply = async () => {
    if (!coupon) return;

    setLoader(true);
    const appointmentType =
      module === "appointment" &&
      (values?.cart?.offers?.length || values?.cart?.products?.length ? "addon" : "offer");
    try {
      const payload = {
        offers: JSON.stringify(userCart.offers),
        variants: JSON.stringify(userCart.variants),
        branchId: module === "eshop" ? "63c10f6aa920ea32701f0b7a" : values?.appointment?.branchId,
        branchCode: values?.appointment?.branchCode,
        promoCode: coupon,
        orderType: appointmentType || module
      };
      const discount = await AddonsService.applyPromo(payload);
      setDiscount(discount.promoDiscount);
      NotificationHelper.success(coupon + " " + t("shop.promoSuccessNotification"), {
        theme: "dark"
      });
    } catch (e) {
      setCoupon("");
      setDiscount(0);
      NotificationHelper.error(coupon + " " + t(e.response.data.error), {
        theme: "dark"
      });
    } finally {
      setLoader(false);
    }
  };

  const daysLeftInDelivery = items.reduce((max, currentItem) => {
    const availability = currentItem.advanceBookingDays;
    return availability > max ? availability : max;
  }, 0);

  const deliveryDate = getFormatedDate(
    moment().add(daysLeftInDelivery + 1, "days"),
    "MMMM DD, YYYY"
  );

  const handleEditCart = (type, item) => {
    if (module === "eshop") editEshopCart({ type, item, cartItems, dispatch, t });
    else editAddonsCart({ type, item, cart: values.cart, setCart, t });
  };

  const handleDeleteItem = (item) => {
    if (module === "eshop") dispatch(removeItem(item));
    else handleAddDeleteItem({ item, type: item.type, cart: values.cart, setCart, t });
    if (items.length === 1) goBack();
  };

  return (
    <>
      <Loader show={loader} />

      {module === "eshop" && <Breadcrumb paymentDisplay={handleOrderAndPayment} />}

      <div className={classes.parent[module]} id="track-checkout">
        <div className={classes.container[module]}>
          <div className="row">
            <div className={classes.childContainer[module][0]}>
              <div className="order-summary">
                <h4>{t("addons.yourOrder")}</h4>
                <div className="items">
                  {module === "appointment" && (
                    <div className="item">
                      <div className="thumbnail">
                        <img src={GET_MEDIA_URL + values.service.image[lang]} />
                      </div>
                      <div className="operations">
                        <div>
                          <div>
                            <h5>{selectedService}</h5>
                            <p>
                              {t("addons.appointment")}{" "}
                              {isEstimation && t("addons.onlineEstimationTag")}
                              {isQuotation && t("addons.onlineQuotationTag")}
                            </p>
                          </div>
                        </div>
                        <div className="product">
                          <span />
                          <h6>
                            {appointmentPrice ? (
                              appointmentPrice + " " + t("addons.currency")
                            ) : (
                              <>
                                {getFormatedDate(appointmentDate, "MMMM DD, YYYY")}
                                {" - "}
                                {appointmentTime}
                                <br />
                                {appointmentBranch}
                              </>
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  )}

                  {items.map((item = {}, key) => {
                    const image =
                      item["thumbnail_" + lang] ||
                      item["image_" + lang] ||
                      item.image ||
                      item.images?.[0]?.value;
                    return (
                      <div className="item" key={key}>
                        <div className="thumbnail">
                          <img src={GET_MEDIA_URL + image} />
                        </div>
                        <div className="operations">
                          <div>
                            <div>
                              <h5>{item.name["value_" + lang]}</h5>
                              <p>{t("addons.cart" + item.type)}</p>
                            </div>
                            {item.type !== "subscription" && (
                              <Icon
                                iconName="trash"
                                className="remove"
                                onClick={() => handleDeleteItem(item)}
                              />
                            )}
                          </div>
                          <div className="product">
                            {item.type === "products" ? (
                              <div className="values">
                                <span className="price">
                                  {item.price + " " + t("addons.currency")}
                                </span>
                                <span className="item-quantity">
                                  <Icon iconName="xmark" />
                                </span>
                                <span className="counter">
                                  <span
                                    className={`${item.quantity > 1}`}
                                    onClick={() => handleEditCart("-", item)}>
                                    <Icon iconName="minus" />
                                  </span>
                                  {item?.quantity}
                                  <span
                                    className={`${item.quantity < item.totalRemainingQuantity}`}
                                    onClick={() => handleEditCart("+", item)}>
                                    <Icon iconName="plus" />
                                  </span>
                                </span>
                              </div>
                            ) : (
                              <span />
                            )}
                            <h6>
                              {item.price * (item.quantity || 1) + " " + t("addons.currency")}
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {(!draftData || showAddons) && (
                <Button type="small-roundedLeft" action={goBack}>
                  {t(
                    module === "subscription" || (module === "appointment" && !showAddons)
                      ? "addons.goBackBtn"
                      : "shop.goToShop"
                  )}
                </Button>
              )}
            </div>

            <div className={classes.childContainer[module][1]}>
              <div className="billing-details">
                <h4>{t("addons.billingDetails")}</h4>
                {module !== "subscription" && !isQuotation && (
                  <div className="coupon-content">
                    <div className="input-wrapper">
                      <Formik
                        initialValues={{
                          coupon_code: ""
                        }}
                        onSubmit={handleCouponApply}>
                        <Form>
                          <label htmlFor="coupon_code">{t("shop.haveACoupon")}</label>
                          <div className="position-relative">
                            <Field
                              type="text"
                              name="coupon_code"
                              id="coupon_code"
                              value={coupon}
                              onChange={(e) => setCoupon(e.target.value)}
                              placeholder={t("shop.couponCodeInp")}
                            />
                            <Button type="simple" btnType="submit" disabled={!coupon}>
                              {t("shop.applyCouponBtn")}
                            </Button>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                )}

                <table className="order-table">
                  <tbody>
                    <tr className="cart-subtotal">
                      <td>{t("addons.subtotal")}</td>
                      <td>
                        {subTotalPrice} {t("addons.currency")}
                      </td>
                    </tr>

                    {module !== "subscription" && (
                      <tr className="cart-promo">
                        <td>{t("addons.promoDiscount")}</td>
                        <td>
                          {promoDiscount} {t("addons.currency")}
                        </td>
                      </tr>
                    )}

                    {values?.appointment?.cad && (
                      <tr className="cart-cnd">
                        <td>{t("addons.collectAndDeliverCharges")}</td>
                        <td>
                          {cadPrice} {t("addons.currency")}
                        </td>
                      </tr>
                    )}

                    <tr className="cart-total">
                      <td>{t("addons.totalAmount")}</td>
                      <td>
                        {totalPrice} {t("addons.currency")}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="pay-form">
                  {/* <UserVAT
                      draftData={draftVals?.userVat}
                      disability={paymentFormDisplay}
                      vatFormRef={vatFormRef}
                      t={t}
                    /> */}

                  <TamaraWidget
                    type="installmentPlan"
                    parentClass="checkout-tag"
                    price={totalPrice}
                  />

                  <h4>{t("addons.paymentOptions")}</h4>
                  <div className="card-and-terms">
                    <>
                      <div className="cards">
                        {paymentCards.map(({ logo, value }) => (
                          <label className="radio-label" key={value}>
                            <input
                              type="radio"
                              name="payment-option"
                              value={value}
                              onChange={handleCardType}
                              checked={value === cardType}
                            />
                            <span className="checkmark"></span>
                            <img src={logo[lang] || logo} />
                          </label>
                        ))}
                      </div>

                      <div className="terms">
                        {module === "subscription" && (
                          <div>
                            <input
                              type="checkbox"
                              name="terms"
                              id="terms"
                              className="custom-check"
                              checked={termsAccepted}
                              onChange={handleTermsChange}
                            />
                            <label htmlFor="terms">{t("addons.agreeTo")} </label>
                          </div>
                        )}
                        <div>
                          <input
                            type="checkbox"
                            name="purchaseType"
                            id="purchaseType"
                            className="custom-check"
                            checked={myselfPurchase}
                            onChange={handlePurchaseTypeChange}
                          />
                          <label htmlFor="purchaseType">
                            {t("addons.purchaseType")}{" "}
                            {/* <a href="/terms-and-conditions" target="_blank">
                                {t("addons.termsAndConditions")}
                              </a> */}
                          </label>
                        </div>
                      </div>

                      <div className="checkout-actions">
                        <Button
                          type="small-roundedRight"
                          action={handleOrderAndPayment}
                          disabled={!termsAccepted || !myselfPurchase}>
                          {t("addons.proceedBtn")}
                        </Button>
                      </div>

                      {/* {vatError && <span className="red-color">{t("addons.vatGeneralError")}</span>} */}
                    </>
                  </div>
                </div>
              </div>

              {module === "eshop" || daysLeftInDelivery ? (
                <div className="collect-notes">
                  <div className="address-details">
                    {module === "eshop" && (
                      <>
                        <h5>{t("shop.collectionNote")}</h5>
                        <p>
                          <span>{t("shop.branchAddressLabel")} </span>
                          {t("shop.branchAddress")}
                        </p>
                        <a href="https://tiny.one/Jed-Safa" target="_blank" rel="noreferrer">
                          {t("shop.viewOnMap")}
                          <Icon iconName="map" />
                        </a>
                      </>
                    )}

                    {daysLeftInDelivery ? (
                      <p style={{ marginTop: "10px" }}>
                        <span>{t("shop.pickupDateLabel")}</span> {deliveryDate}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {paymentFormDisplay && (
        <PaymentProcessor show={paymentFormDisplay} hide={handlPaymentFormDisplay} />
      )}
    </>
  );
};

export default React.memo(OrdersCheckout);
