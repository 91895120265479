import axios from "axios";
import {
  API_GET_MEDIA,
  API_GET_PUBLISHED_ROUTES,
  API_GET_SETTINGS,
  API_UPLOAD_MEDIA
} from "../config/webService";
import BaseService from "../config/baseService";

class AppServices extends BaseService {
  async getPages() {
    const result = await axios.get(API_GET_PUBLISHED_ROUTES);
    const response = result?.data?.response?.map((item) => item.name);
    return response;
  }

  async getMedia() {
    const response = await axios.get(API_GET_MEDIA);
    return response && response.data;
  }

  async getSettings() {
    const result = await axios.get(API_GET_SETTINGS);
    return result?.data;
  }

  async uploadMedia(file) {
    const formData = new FormData();
    formData.append("file", file);

    const result = await axios.post(API_UPLOAD_MEDIA, formData);
    return result?.data?.key;
  }
}

export default new AppServices();
