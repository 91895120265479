import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppNavigation } from "../../hooks";
import { currentLanguage, logEvent } from "../../util/common";

const FooterButtons = () => {
  const { t } = useTranslation();

  const pages = useSelector((state) => state.content.pages) || [];
  const { localeRoutes } = useAppNavigation();

  const lang = currentLanguage();

  return (
    <>
      {(pages.includes("estimation") ||
        pages.includes("quotation") ||
        pages.includes("schedule") ||
        pages.includes("track") ||
        pages.includes("eshop")) && (
        <div className="company-box col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
          {window.innerWidth > 575 && (
            <span className="group-label">{t("footer.customerService")}</span>
          )}
          <div className="services-wrapper">
            {pages.includes("estimation") && (
              <Link
                to={localeRoutes.repairEstimate[lang]}
                onClick={() => {
                  logEvent("Footer", "footer_button_click", "Estimation Button");
                }}
                className="book-apnt-btn">
                {t("footer.estimationRequest")}
              </Link>
            )}
            {pages.includes("schedule") && (
              <Link
                to={localeRoutes.bringIt[lang]}
                onClick={() => {
                  logEvent("Footer", "footer_button_click", "Schedule Appointment Button");
                }}
                className="book-apnt-btn  blue-color-btn">
                {t("footer.bookApointment")}
              </Link>
            )}
            {pages.includes("quotation") && (
              <Link
                to={localeRoutes.bringIt[lang]}
                // onClick={() => {
                //   logEvent("Footer", "footer_button_click", "Schedule Appointment Button");
                // }}
                className="book-apnt-btn">
                {t("footer.quotation")}
              </Link>
            )}
            {pages.includes("track") && (
              <Link
                to={localeRoutes.track[lang]}
                onClick={() => {
                  logEvent("Footer", "footer_button_click", "Track Button");
                }}
                className="book-apnt-btn blue-color-btn">
                {t("footer.track")}
              </Link>
            )}
            {pages.includes("eshop") && (
              <Link
                to={localeRoutes.eshop[lang]}
                onClick={() => {
                  logEvent("Footer", "footer_button_click", "Shop Button");
                }}
                className="book-apnt-btn">
                {t("footer.shop")}
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FooterButtons;
