import React from "react";
import { currentLanguage } from "../../util/common";
import { domainOrigin } from "../../util/constants";
import { GET_MEDIA_URL } from "../../config/webService";
import { useSelector } from "react-redux";
import "./index.scss";

const NewPrivacyPolicyPage = () => {
  const lang = currentLanguage();
  const { media } = useSelector((state) => state.content);

  return (
    <div className="wrapper">
      <div className="imageContainer">
        <img src={GET_MEDIA_URL + media["aljLogo_" + lang]} width="350px" />
      </div>
      {lang === "en" ? (
        <div className="englishPara">
          <p>Important Update: Changes to Our Privacy Policy </p>
          <p>
            {" "}
            We are updating our <span className="aljLink">Privacy Policy</span> to make it easier
            for you to understand what information we collect and why we collect it. These updates
            are designed to provide you with more transparency and control over how your personal
            data is used, particularly for marketing purposes.{" "}
          </p>
          <p>
            {" "}
            As part of our commitment to protecting your privacy, we rely on the existing consent
            you have given for marketing activities. If you have already provided consent, we will
            continue to respect your preferences. Our updated policy now includes additional details
            on how we process your personal data for marketing.
          </p>{" "}
          <p>
            We are making these updates as we should be compliant with KSA Personal Data Protection
            Regulations by September 14, 2024. And we’re taking this opportunity to enhance our
            privacy practices for all our customers.{" "}
          </p>{" "}
          <p>
            You can view the revised policy{" "}
            <a
              href={`${domainOrigin}/en/privacy-policy`}
              target="_blank"
              rel="noreferrer"
              className="aljLink">
              here.
            </a>{" "}
            In this policy, we have also included an option for you to withdraw your consent for
            marketing at any time.{" "}
          </p>
          <p>
            You have received this mandatory email service announcement to update you about
            important changes to your customer account with us.
          </p>{" "}
          <p>
            If you have any questions or need further assistance, please don’t hesitate to contact
            the Data Protection Officer via email at{" "}
            <a href="mailto:ALJEDPO@alj.com" className="aljLink">
              ALJEDPO@alj.com.
            </a>
          </p>
          <p>Thank you for your continued trust and support.</p>
          <p>Abdul Latif Jameel Body Works & Paint Co. - AutoHub Team</p>
        </div>
      ) : (
        <div className="arabicPara">
          <p>تحديث مهم: التغييرات في سياسة الخصوصية الخاصة بشركة عبداللطيف جميل للأراضي</p>
          <p>
            نقوم بتحديث <span className="aljLink">سياسة الخصوصية</span> الخاصة بنا لإيضاح المعلومات
            التي نقوم بجمعها ولماذا نجمعها. تم تصميم هذه التحديثات لتزويدك بمزيد من الشفافية والتحكم
            في كيفية إستخدام بياناتك الشخصية، خاصة لأغراض التسويق.
          </p>
          <p>
            كجزء من إلتزامنا بحماية خصوصيتك، نعتمد على الموافقة التي قدمتها للأنشطة التسويقية. إذا
            كنت قد قدمت موافقتك بالفعل، فسنستمر في إحترام تفضيلاتك. تتضمن سياستنا المحدثة الآن
            تفاصيل إضافية حول كيفية معالجتنا لبياناتك الشخصية لأغراض التسويق.
          </p>
          <p>
            نحن نعمل على هذه التحديثات تماشياً مع ضرورة الإلتزام مع البيانات الجديدة لحيز التنفيذ في
            14 سبتمبر 2024 في المملكة العربية السعودية، وننتهز هذه الفرصة لتعزيز ممارسات الخصوصية
            لجميع عملائنا.
          </p>
          <p>
            يمكنك الإطلاع على السياسة المحدثة
            <a
              href={`${domainOrigin}/privacy-policy`}
              target="_blank"
              className="aljLink"
              rel="noreferrer">
              هنا.
            </a>
            في هذه السياسة، قمنا أيضاً بتضمين خيار لك لسحب موافقتك بخصوص التسويق في أي وقت.
          </p>
          <p>
            لقد تلقيت إعلان خدمة البريد الإلكتروني الإلزامي هذا لإطلاعك على التغييرات المهمة في حساب
            العميل الخاص بك معنا.
          </p>
          <p>
            إذا كانت لديك أي أسئلة أو كنت بحاجة إلى مزيد من المساعدة، فيرجى عدم التردد في الاتصال
            بمسؤول حماية البيانات عبر البريد الإلكتروني على
            <a href="mailto:ALJEDPO@alj.com" className="aljLink">
              ALJEDPO@alj.com.
            </a>
          </p>
          <p>شكرا لك على ثقتك ودعمك المستمرين.</p>
          <p>فريق عمل شركة عبداللطيف جميل للسمكرة والدهان - أوتوهب</p>
        </div>
      )}
    </div>
  );
};

export default NewPrivacyPolicyPage;
