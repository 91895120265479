import React from "react";
import { Helmet } from "react-helmet";
import { isLtr } from "../../util/common";
import { useTranslation } from "react-i18next";

const AppHelmet = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="google-site-verification"
          content="H4RDJsA-vn6qT9m_GD-Wk79OktmYUodJjehCqiztMZU"
        />
        <title>{isLtr() ? t("home.autohub") : t("home.autohub")}</title>
      </Helmet>
    </>
  );
};

export default AppHelmet;
