import axios from "axios";
import {
  API_GET_QUOTATIONS,
  API_GET_REQUESTED_QUOTES,
  API_GET_REQUESTED_QUOTE_BY_ID,
  API_REQUEST_QUOTE
} from "../config/webService";
import BaseService from "../config/baseService";
import { createCarNickName } from "../util/common";

class QuotationService extends BaseService {
  async getQuotations(slug) {
    const params = { isActive: true };
    if (slug) params.slugUrl = slug;
    const result = await axios.get(API_GET_QUOTATIONS, { params });
    return result && result.data?.response;
  }

  async requestAQuote(values) {
    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("phoneNumber", values.mobilePhone);
    formData.append("email", values.email);
    formData.append("make", values.Brand);
    formData.append("model", values.Model);
    formData.append("year", values.Year);
    formData.append("otherBrand", values.OtherBrand);
    formData.append("otherModel", values.OtherModel);
    formData.append("licencePlate", values.plateNumber + values.plateChar);
    formData.append("branchId", values.branchId);
    formData.append("quotationId", values.quotationId);
    formData.append("quoteIdHash", values.quoteIdHash);
    formData.append("language", values.lang);
    formData.append("serviceName", JSON.stringify(values.serviceName));

    const quoteDetails = values.quoteDetails.map((item, index) => {
      if (item.answer instanceof File) {
        const fileKey = `image${index}`;
        formData.append(fileKey, item.answer);
        return {
          ...item,
          key: fileKey
        };
      }
      return item;
    });
    formData.append("quoteDetails", JSON.stringify(quoteDetails));

    formData.append(
      "carNickName",
      createCarNickName({
        username: values.firstName,
        carNickName: values.carNickName,
        model: values.Model,
        otherModel: values.OtherModel
      })
    );

    const result = await axios.post(API_REQUEST_QUOTE, formData);
    return result?.data;
  }

  async getRequestedQuotes(phoneNumber) {
    const result = await axios.get(API_GET_REQUESTED_QUOTES, { params: { phoneNumber } });
    return result.data?.response;
  }

  async getRequestQuoteById(id) {
    const result = await axios.get(API_GET_REQUESTED_QUOTE_BY_ID + id);
    return result.data?.response;
  }
}

export default new QuotationService();
