import * as Yup from "yup";

export const damageImagesValidations = () => {
  return Yup.object().shape({
    RegistrationCard: Yup.string().required("estimationRequest.required")
  });
};

export const damageDetailsValidations = () => {
  return Yup.object().shape({
    HowHappen: Yup.string().required("estimationRequest.required"),
    DamageMonth: Yup.string().required("estimationRequest.required"),
    DamageYear: Yup.string().required("estimationRequest.required"),
    City: Yup.string().required("estimationRequest.required"),
    Provider: Yup.string().required("estimationRequest.required"),
    paymentMethod: Yup.string().required("estimationRequest.required"),
    insurancePdf: Yup.string().when("paymentMethod", {
      is: "insurance",
      then: (schema) => schema.required("estimationRequest.required")
    })
  });
};

export const carImageValidations = () => {
  return Yup.object().shape({
    CarImage: Yup.string().required("estimationRequest.required")
  });
};

export const agreementValidations = () => {
  return Yup.object().shape({
    Terms: Yup.bool().oneOf([true], "estimationRequest.required")
  });
};
