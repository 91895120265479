import BaseService from "../config/baseService";
import axios from "axios";
import {
  API_GET_AUTOHUB_SERVICE_CATEGORIES,
  API_GET_CATEGORIZED_OFFERS,
  API_GET_OFFERS,
  API_GET_TOP_OFFERS
} from "../config/webService";

class CampaignService extends BaseService {
  constructor(params) {
    super();
  }

  #request = async (options) => {
    try {
      const result = await axios(options);
      return result?.data?.response;
    } catch (error) {
      console.error(error);
      return error.message;
    }
  };

  async getCampaign(slug) {
    return await this.#request({
      url: API_GET_OFFERS,
      method: "GET",
      params: {
        slug
      }
    });
  }
  async getCategorizedCampaign({ pageIndex, pageSize, categoryId }) {
    return await this.#request({
      url: API_GET_CATEGORIZED_OFFERS,
      method: "GET",
      params: {
        pageIndex: Number(pageIndex),
        pageSize: Number(pageSize),
        categoryId
      }
    });
  }
  async getServiceCategorizes({ isPrePaid }) {
    return await this.#request({
      url: API_GET_AUTOHUB_SERVICE_CATEGORIES,
      method: "GET",
      params: {
        forCMS: false,
        isPrePaid
      }
    });
  }
}

export default new CampaignService();
